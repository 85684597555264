import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    main: {
        backgroundColor: '#434242',
        paddingTop: '25px'
    },
    text: {
        color:'white',
        paddingTop: '25px',
        paddingBottom: '25px'
    }
});

class Contact extends Component{
    render(){
        const {classes} = this.props;
        return(
            <div className={classes.main}>
                <Typography className={classes.text}
                                variant='h2'
                                align='center'>
                        Contact
                </Typography>
                <Typography className={classes.text}
                            variant='h6'
                            align='center'>
                    cantonioli01@gmail.com
                </Typography>
            </div>
        );
    }
}

export default withStyles(styles)(Contact);