import React, {Component} from 'react';
import Grade from '@material-ui/icons/Grade';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    fullStar:{
        color: '#6A1B9A',
    },
    emptyStar:{
        color: '#CE93D8',
    },
    stars:{
        paddingTop: '0.3em',
    }
});

class Stars extends Component{
    render(){
        const {classes, rating} = this.props;

        let grades = [];
        for(var i = 1; i <= 5; i++){
            if(rating >= i){
                grades.push(<Grade key={i}
                                   fontSize='small'
                                   className={classes.fullStar}></Grade>);
            }
            else {
                grades.push(<Grade key={i}
                                   fontSize='small' 
                                   className={classes.emptyStar}></Grade>);
            }
        }

        return(
            <div className={classes.stars} align="right">
                {grades}
            </div>
        );
    }
}

export default withStyles(styles)(Stars);