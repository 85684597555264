import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Code from '@material-ui/icons/Code';
import School from '@material-ui/icons/School';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
    main: {
        backgroundColor: '#F3EFE0',
        paddingTop: '25px'
    },
    text: {
        color: 'black',
        paddingTop: '25px',
        paddingBottom: '25px'
    },
    education: {
        borderTop: '3px solid #e91e63',
    },
    subtitle: {
        color: 'gray',
        paddingTop: '0.5em',
    },
    chip: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },

});

class Projects extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.main}>
                <Typography className={classes.text}
                    variant='h2'
                    align='center'>
                    Experiences
                </Typography>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2021 - Present"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<Code />}
                    >
                        <h3 className="vertical-timeline-element-title">Lead Software Engineer - Manager/Tech Lead</h3>
                        <h4 className={"vertical-timeline-element-subtitle " + classes.subtitle} >Louisville, KY</h4>
                        <ul>
                            <li> <b>Manage</b> and <b>Lead</b> a team of software architects, engineers and developers to deliver software packages for customer projects. </li>
                            <li> <b>Coordinate</b> and oversee design and developments of <b>future products</b>. </li>
                            <li> Provide technical and team <b>leadership</b> through coaching and mentorship of a dedicated resource team. </li>
                            <li> <b>Organize</b> and lead weekly team meetings.</li>
                            <li> Organize <b>one on one meetings</b> with all direct reports.</li>
                            <li> Work with scheduling team on <b>resource allocation</b> for projects</li>
                            <li> Provide <b>estimates</b> of hours needed for new projects and/or new changes for current projects</li>
                            <li> Approval of <b>expense reports</b>, vacations, sick days, etc.</li>
                            <li> Conduct yearly <b>performance reviews</b></li>
                            <li> Coordinate merit increases based on <b>performance</b></li>
                            <li> Coordinate promotions with Director of Software</li>
                            <li> <b>Code Reviews</b></li>
                            <li> Present and communicate with <b>key stakeholders</b> of projects and <b>product progress</b></li>
                            <li> Conduct <b>interviews</b> for new candidates</li>
                        </ul>
                        <div>
                            <Chip label="C#" className={classes.chip}></Chip>
                            <Chip label="C++" className={classes.chip}></Chip>
                            <Chip label="SQL" className={classes.chip}></Chip>
                            <Chip label=".NET" className={classes.chip}></Chip>
                            <Chip label="WPF" className={classes.chip}></Chip>
                            <Chip label="WCF" className={classes.chip}></Chip>
                            <Chip label="Windows Forms" className={classes.chip}></Chip>
                            <Chip label="ASP.NET" className={classes.chip}></Chip>
                            <Chip label="Vue JS" className={classes.chip}></Chip>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2020 - 2021"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<Code />}
                    >
                        <h3 className="vertical-timeline-element-title">Senior Software Engineer at Bastian Solutions</h3>
                        <h4 className={"vertical-timeline-element-subtitle " + classes.subtitle} >Louisville, KY</h4>
                        <ul>
                            <li> <b>Full Stack Engineer</b> architecting, designing and developing Microsoft .NET based software with modern web frameworks such as Vue. </li>
                            <li> <b>Coaching and mentorship</b> of junior engineers</li>
                            <li> <b>Code Reviews</b></li>
                            <li> Participate in <b>interviews</b> for new candidates</li>
                        </ul>
                        <div>
                            <Chip label="C#" className={classes.chip}></Chip>
                            <Chip label="C++" className={classes.chip}></Chip>
                            <Chip label="SQL" className={classes.chip}></Chip>
                            <Chip label=".NET" className={classes.chip}></Chip>
                            <Chip label="WPF" className={classes.chip}></Chip>
                            <Chip label="WCF" className={classes.chip}></Chip>
                            <Chip label="Windows Forms" className={classes.chip}></Chip>
                            <Chip label="ASP.NET" className={classes.chip}></Chip>
                            <Chip label="Vue JS" className={classes.chip}></Chip>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2015 - 2020"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<Code />}
                    >
                        <h3 className="vertical-timeline-element-title">Software Engineer at Bastian Solutions</h3>
                        <h4 className={"vertical-timeline-element-subtitle " + classes.subtitle} >Louisville, KY</h4>
                        <ul>
                            <li> <b>Full Stack Engineer</b> designing and developing Microsoft .NET based software with modern web frameworks such as Vue.</li>
                            <li> <b>Coaching and mentorship</b> of interns</li>
                            <li> <b>Code Reviews</b></li>
                        </ul>
                        <div>
                            <Chip label="C#" className={classes.chip}></Chip>
                            <Chip label="C++" className={classes.chip}></Chip>
                            <Chip label="SQL" className={classes.chip}></Chip>
                            <Chip label=".NET" className={classes.chip}></Chip>
                            <Chip label="WPF" className={classes.chip}></Chip>
                            <Chip label="WCF" className={classes.chip}></Chip>
                            <Chip label="Windows Forms" className={classes.chip}></Chip>
                            <Chip label="ASP.NET" className={classes.chip}></Chip>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2014 - 2015"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<Code />}
                    >
                        <h3 className="vertical-timeline-element-title">Software Design Engineer at AdvantaChart</h3>
                        <h4 className={"vertical-timeline-element-subtitle " + classes.subtitle} >Prospect, KY</h4>
                        <p>
                            Developed eletronics health records and billing system using Microsoft Technologies.
                        </p>
                        <div>
                            <Chip label="C#" className={classes.chip}></Chip>
                            <Chip label="VB" className={classes.chip}></Chip>
                            <Chip label="SQL" className={classes.chip}></Chip>
                            <Chip label=".NET" className={classes.chip}></Chip>
                            <Chip label="Crystal Reports" className={classes.chip}></Chip>
                            <Chip label="WCF" className={classes.chip}></Chip>
                            <Chip label="Windows Forms" className={classes.chip}></Chip>
                            <Chip label="ASP.NET" className={classes.chip}></Chip>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date="2013 - 2014"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        icon={<Code />}
                    >
                        <h3 className="vertical-timeline-element-title">Software Developer Intern at AdvantaChart</h3>
                        <h4 className={"vertical-timeline-element-subtitle " + classes.subtitle} >Prospect, KY</h4>
                        <p>
                            Interned working with eletronics health records and billing system using Microsoft Technologies.
                        </p>
                        <div>
                            <Chip label="C#" className={classes.chip}></Chip>
                            <Chip label="VB" className={classes.chip}></Chip>
                            <Chip label="SQL" className={classes.chip}></Chip>
                            <Chip label=".NET" className={classes.chip}></Chip>
                            <Chip label="Crystal Reports" className={classes.chip}></Chip>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2010 - 2013"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<School />}
                    >
                        <h3 className="vertical-timeline-element-title">Bachelor of Science in Computer Engineering at Bellarmine University</h3>
                        <h4 className={"vertical-timeline-element-subtitle " + classes.subtitle} >Minor in Mathematics</h4>
                        <h4 className={"vertical-timeline-element-subtitle " + classes.subtitle} >Louisville, KY</h4>
                        <p>
                            Algorithms, Programming, Design, Networking, Computer and Software Architecture.
                        </p>
                        <div>
                            <Chip label="iOS" className={classes.chip}></Chip>
                            <Chip label="Android" className={classes.chip}></Chip>
                            <Chip label="Unity3D" className={classes.chip}></Chip>
                            <Chip label=".NET" className={classes.chip}></Chip>
                            <Chip label="Linux" className={classes.chip}></Chip>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="2006 - 2010"
                        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        icon={<School />}
                    >
                        <h3 className="vertical-timeline-element-title">Colégio Técnico de Campinas (Vocational School)</h3>
                        <h4 className={"vertical-timeline-element-subtitle " + classes.subtitle} >Campinas, São Paulo, Brazil</h4>
                        <p>
                            Electronics, Low Level Programming.
                        </p>
                        <div>
                            <Chip label="Microprocessors" className={classes.chip}></Chip>
                            <Chip label="Microcontrollers" className={classes.chip}></Chip>
                            <Chip label="Machine Language" className={classes.chip}></Chip>
                            <Chip label="Assembly" className={classes.chip}></Chip>
                        </div>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </div>
        );
    }
}

export default withStyles(styles)(Projects);