import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    main: {
        paddingTop: '100px',
        paddingBottom: '40px',
        paddingLeft: '2vw',
        paddingRight: '2vw',
        minHeight: '100vh',
        boxSizing: 'border-box',
        position: 'fixed',
        top: 0,
        width: '25%',
        zIndex: 100,
        background:
            'linear-gradient(rgba(135, 31, 120, 0.45), rgba(135, 31, 120, 0.45)),url("/images/background.jpg")',
        backgroundPosition: 'center', /* Center the image */
        backgroundRepeat: 'no-repeat', /* Do not repeat the image */
        backgroundSize: 'cover', /* Resize the background image to cover the entire container */
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    image: {
        width: '16vw',
        height: '16vw',
        margin: 'auto',
        borderRadius: '50%',
        border: '6px solid #FFFFFF',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        backgroundImage: 'url("/images/profile_eduardo.jpeg")', /* The image used */
        backgroundPosition: 'center top', /* Center the image */
        backgroundRepeat: 'no-repeat', /* Do not repeat the image */
        backgroundSize: 'cover' /* Resize the background image to cover the entire container */
    },
    text: {
        color: 'white',
        paddingTop: '25px',
        textShadow: '2px 2px 4px #000000',
    },
});

class ProfileDesktop extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.main}>
                <div className={classes.image}></div>
                <Typography className={classes.text}
                    variant='h5'
                    align='center'>
                    <b>Carlos Eduardo Antonioli</b>
                </Typography>
                <Typography className={classes.text}
                    variant='h6'
                    align='center'>
                    <b>Full Stack Engineer and Leader</b>
                </Typography>
            </div>
        );
    }
}

export default withStyles(styles)(ProfileDesktop);