import React, {Component} from 'react';
import SkillElement from './skill_element';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';

const styles = theme => ({
    main: {
        //marginTop: '100px',
        padding: theme.spacing.unit * 3,
    },
    sectionHeader:{
        marginTop: '50px',
        marginBottom: '50px',
    },
    card: {
        padding: theme.spacing.unit * 2,
        borderRadius: '10px',
    }
});

class Skills extends Component {
    render(){
        const {classes} = this.props;
        return (
                <div className={classes.main}>
                        <Typography variant='h2'
                                    align='center'>Skills</Typography>
                                <Typography variant="h4"
                                                    className={classes.sectionHeader}>Web</Typography>
                                <Card className={classes.card}>
                                    <Grid container
                                        spacing={8}>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="ASP.NET" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="CSS" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="HTML" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="AJAX" skillRating={5}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="Javascript" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="MVC3" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="React + Redux" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="Vue Js" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="JQuery" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="Node JS" skillRating={4}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SkillElement skillName="Ruby On Rails" skillRating={3}/>
                                        </Grid>
                                    </Grid>
                            </Card>


                            <Typography variant="h4"
                                        className={classes.sectionHeader}>Databases</Typography>
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Oracle SQL" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Microsoft SQL Server" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="MySQL" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="MongoDB" skillRating={3}/>
                                    </Grid>
                                </Grid>
                            </Card>
                            
                            <Typography variant="h4"
                                        className={classes.sectionHeader}>ORM</Typography>
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Nhibernate" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="GORM" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Entity Framework" skillRating={3}/>
                                    </Grid>
                                </Grid>
                            </Card>

                            
                            <Typography variant="h4"
                                        className={classes.sectionHeader}>Programming Languages</Typography>
                            
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="C# .NET" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="VB .NET" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="C/C++" skillRating={3}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Objective-C" skillRating={3}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Golang" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Java" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Python" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Shell Scripting" skillRating={3}/>
                                    </Grid>
                                </Grid>
                            </Card>

                            <Typography variant="h4"
                                        className={classes.sectionHeader}>Frameworks</Typography>
                            
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Unity 3D" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Windows Communication Foundation" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Windows Presentation Foundation" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Windows Forms" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Web Forms" skillRating={4}/>
                                    </Grid>
                                </Grid>
                            </Card>


                            <Typography variant="h4"
                                        className={classes.sectionHeader}>Source Control</Typography>
                            
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Git" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Team Foundation Server" skillRating={4}/>
                                    </Grid>
                                </Grid>
                            </Card>
                            

                            <Typography variant="h4"
                                        className={classes.sectionHeader}>Continuous Integration / Continuous Delivery</Typography>
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Drone" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Azure Dev Ops" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="TFS CI" skillRating={4}/>
                                    </Grid>
                                </Grid>
                            </Card>


                            <Typography variant="h4"
                                        className={classes.sectionHeader}>Dev Ops</Typography>
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Docker" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Swarm" skillRating={4}/>
                                    </Grid>
                                </Grid>
                            </Card>
                            

                            <Typography variant="h4"
                                        className={classes.sectionHeader}>Infrastructure</Typography>
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Linux" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Windows Server" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Internet Information Systems (IIS)" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Nginx" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Traefik" skillRating={3}/>
                                    </Grid>
                                </Grid>
                            </Card>
                           

                            <Typography variant="h4"
                                        className={classes.sectionHeader}>Spoken Languages</Typography>
                            <Card className={classes.card}>
                                <Grid container
                                    spacing={8}>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Brazilian Portuguese" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="English" skillRating={5}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Spanish" skillRating={4}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SkillElement skillName="Italian" skillRating={3}/>
                                    </Grid>
                                </Grid>
                            </Card>
                    </div>  
        );
    }
}

export default withStyles(styles)(Skills);