import React, {Component} from 'react';
import ProfileDesktop from './profile_desktop';
import ProfileMobile from './profile_mobile';
import Resume from './resume';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const styles = theme => ({
    main:{
        backgroundColor: theme.palette.background.default,
    }
});

class App extends Component{
    render(){
        const {classes} = this.props;
        if (isWidthUp('md', this.props.width)) { 
            return (
                <div className={classes.main}>
                    <Grid container
                        direction="row">
                        <Grid item
                            xs={3}
                            className={classes.test}> 
                            <ProfileDesktop />
                        </Grid>
                        <Grid item
                            xs={9}>
                            <Resume />
                        </Grid> 
                    </Grid>
                </div>  
            );
        }
        else {
            return (
                <div className={classes.main}>
                    <ProfileMobile></ProfileMobile>
                    <Resume></Resume>
                </div>
            );
        }
    }
}

export default withWidth()(withStyles(styles)(App));