import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Stars from './stars';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    main: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    skillName:{
        color: '#707070'
    }
});

class SkillElement extends Component {

    render(){
        const { skillName, skillRating, classes } = this.props;
        return (
            <Grid container
                justify="space-between"
                className={classes.main}>
                <Grid item xs={7}>
                    <Typography variant='h6'
                                className={classes.skillName}>{skillName}</Typography>     
                </Grid>
                <Grid item xs={5}>
                    <Stars rating={skillRating}></Stars>  
                </Grid>
            </Grid>

        );

    }
}


export default withStyles(styles)(SkillElement);