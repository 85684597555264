import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Stars from './stars';
import Contact from './contact';
import Projects from './projects';
import Experiences from './experiences';
import Skills from './skills';

const styles = theme => ({
    main: {
    },
    content:{
        maxWidth: '900px',
        margin: 'auto',
    },
    projectsWrapper: {
        backgroundColor: '#F3EFE0',
        boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.4), inset 0 -7px 9px -7px rgba(0,0,0,0.5)',
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    sectionHeader:{
        marginTop: '25px',
        marginBottom: '25px',
    }
});

class Resume extends Component{
    render(){
        const {classes} = this.props;
        return(
            <div className={classes.main}>
                <div>
                    <Experiences></Experiences>
                </div>
                <div className={classes.content}>
                    <Skills></Skills>
                </div> 
                <div className={classes.projectsWrapper}>
                    <div className={classes.content}>
                        <Projects></Projects>
                    </div>
                </div>
                <div>
                    <Contact></Contact>
                </div>
            </div>

            
        );
    }
}

export default withStyles(styles)(Resume);