import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
    main: {
        padding: theme.spacing.unit * 2,
        marginBottom: '50px',
    },
    text: {
        color:'black',
        paddingTop: '5px',
        paddingBottom: '25px'
    },
    card: {
        backgroundPosition: 'center top'
    },
    media: {
        paddingBottom: '75%',
    },
    chip: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});

class Projects extends Component{
    render(){
        const {classes} = this.props;
        return(
            <div className={classes.main}>
                <Typography className={classes.text}
                                variant='h2'
                                align='center'>
                        Projects
                </Typography>
                
                <Grid container
                      spacing={24}>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <CardMedia
                            className={classes.media}
                            image="/images/bounceitupdual.jpg"
                            title="Bounce It Up!"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Bounce It Up!
                                </Typography>
                                <Typography component="p">
                                    iOS Game for iPhone and iPad.
                                </Typography>
                                <Chip label="Objective-C" className={classes.chip}></Chip>
                                <Chip label="iOS" className={classes.chip}></Chip>
                                <Chip label="Twitter API" className={classes.chip}></Chip>
                                <Chip label="Cocos2D" className={classes.chip}></Chip>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <CardMedia
                            className={classes.media}
                            image="/images/IconSpaceship.png"
                            title="Space Legend 3D"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Space Legend 3D
                                </Typography>
                                <Typography component="p">
                                    Multiplatform game utilizing the accelerometer.
                                </Typography>
                                <Chip label="C#" className={classes.chip}></Chip>
                                <Chip label="Javascript" className={classes.chip}></Chip>
                                <Chip label="iOS" className={classes.chip}></Chip>
                                <Chip label="Android" className={classes.chip}></Chip>
                                <Chip label="Unity 3D" className={classes.chip}></Chip>
                            </CardContent>
                    </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <CardMedia
                            className={classes.media}
                            image="/images/chickenPhoto.png"
                            title="Code Egg"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Code E.G.G
                                </Typography>
                                <Typography component="p">
                                    Multiplatform game utilizing touch events.
                                </Typography>
                                <Chip label="C#" className={classes.chip}></Chip>
                                <Chip label="iOS" className={classes.chip}></Chip>
                                <Chip label="Android" className={classes.chip}></Chip>
                                <Chip label="Web" className={classes.chip}></Chip>
                                <Chip label="Unity 3D" className={classes.chip}></Chip>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <CardMedia
                            className={classes.media}
                            image="/images/jumpydual.jpg"
                            title="Jumpy Angel 3D"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Jumpy Angel 3D
                                </Typography>
                                <Typography component="p">
                                    Mobile game cloning the famous Flappy Bird.
                                </Typography>
                                <Chip label="C#" className={classes.chip}></Chip>
                                <Chip label="iOS" className={classes.chip}></Chip>
                                <Chip label="Android" className={classes.chip}></Chip>
                                <Chip label="Unity 3D" className={classes.chip}></Chip>
                                <Chip label="Facebook API" className={classes.chip}></Chip>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <CardMedia
                            className={classes.media}
                            image="/images/react.png"
                            title="Website"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Interactive Resume
                                </Typography>
                                <Typography component="p">
                                    This interactive resume is written using the latest web techs.
                                </Typography>
                                <Chip label="React" className={classes.chip}></Chip>
                                <Chip label="Javascript" className={classes.chip}></Chip>
                                <Chip label="HTML" className={classes.chip}></Chip>
                                <Chip label="CSS" className={classes.chip}></Chip>
                                <Chip label="Material-UI" className={classes.chip}></Chip>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Projects);






// const styles = {
//   card: {
//     maxWidth: 345,
//   },
//   media: {
//     height: 140,
//   },
// };


